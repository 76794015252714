import Vue from 'vue'
import Router from 'vue-router'
import { isLogined } from '@/utils/authorized'
import Admin from './layout/admin.vue'
import Dashboard from './views/Dashboard.vue'
import CaseBatch from './views/CaseBatch/index.vue'
import Placeholder from './views/Placeholder.vue'
import Login from './views/Login.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Admin,
      redirect: {
        path: '/login'
      },
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: Placeholder,
          meta: {
            breadcrumbName: '首页'
          }
        },
        {
          path: 'claims',
          name: 'claims',
          component: Dashboard,
          meta: {
            breadcrumbName: '就诊记录'
          }
        },
        {
          path: 'preAuth',
          name: 'pre-auth',
          meta: {
            breadcrumbName: '事先授权'
          },
          component: () =>
            import(/* webpackChunkName: "pre-auth" */ './views/PreAuth.vue')
        },
        {
          path: 'caseBatch',
          name: 'caseBatch',
          component: CaseBatch,
          meta: {
            breadcrumbName: '案件批次'
          }
        },
        {
          // 案件批次新建
          path: '/createCaseBatch',
          name: 'createCase',
          component: () =>
            import(
              /* webpackChunkName: "auth-detail" */
              './views/CaseBatch/Create.vue'
            )
        },
        {
          // 案件批次管理
          path: '/managerCaseBatch/:id',
          name: 'create-case',
          component: () =>
            import(
              /* webpackChunkName: "auth-detail" */
              './views/CaseBatch/Manager.vue'
            )
        },
        {
          // 案件批次管理-添加
          path: '/addCaseBatch/:id',
          name: 'create-case',
          component: () =>
            import(
              /* webpackChunkName: "auth-detail" */
              './views/CaseBatch/AddCaseBatch.vue'
            )
        },
        {
          path: '/authDetail/:id',
          name: 'auth-detail',
          component: () =>
            import(
              /* webpackChunkName: "auth-detail" */
              './views/AuthDetail.vue'
            )
        },
        {
          path: '/claimDetail/:id',
          name: 'claim-detail',
          component: () =>
            import(
              /* webpackChunkName: "auth-detail" */
              './views/ClaimDetail.vue'
            )
        },
        {
          path: 'welfare',
          name: 'welfare',
          meta: {
            breadcrumbName: '福利查询'
          },
          component: () =>
            import(/* webpackChunkName: "pre-auth" */ './views/Welfare.vue')
        },
        {
          path: 'CreateBarCode',
          name: 'cre-barCode',
          meta: {
            breadcrumbName: '批量生成条形码'
          },
          component: () =>
            import(
              /* webpackChunkName: "pre-auth" */
              './views/CreateBarCode.vue'
            )
        },
        {
          path: 'ORCDiscern',
          name: 'OCR-discern',
          meta: {
            breadcrumbName: 'OCR影像识别纠错'
          },
          component: () =>
            import(/* webpackChunkName: "pre-auth" */ './views/ORCDiscern.vue')
        },
        {
          path: 'ORCList',
          name: 'OCR-list',
          meta: {
            breadcrumbName: '影像件批量上传列表'
          },
          component: () =>
            import(/* webpackChunkName: "pre-auth" */ './views/ORCList.vue')
        },
        {
          path: 'coupons',
          name: 'coupon-list',
          meta: {
            breadcrumbName: '优惠卡查询'
          },
          component: () =>
            import(/* webpackChunkName: "coupons" */ './views/Coupons.vue')
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/changepwd',
      name: 'change-pwd',
      component: () =>
        import(/* webpackChunkName: "change-pwd" */ './views/ChangePwd.vue')
    },
    {
      path: '/my',
      name: 'my',
      component: () => import('./views/medilink/my.vue')
    },
    {
      path: '/udeskContact',
      name: 'udeskContact',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "test" */ './views/UdeskContact.vue')
    },
    {
      path: '/udeskMedicalPreference',
      name: 'udeskMedicalPreference',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "test" */
          './views/UdeskMedicalPreference.vue'
        )
    },
    {
      // 中间带官网
      path: '/MediLink',
      name: 'MediLink',
      component: () => import('./views/medilink/index.vue')
    },
    {
      // 关于中间带
      path: '/MediLink/about',
      name: 'about',
      component: () => import('./views/medilink/about.vue')
    },
    {
      // 关于产品
      path: '/MediLink/product',
      name: 'product',
      component: () => import('./views/medilink/product.vue')
    },
    {
      // 关于健康
      path: '/MediLink/health',
      name: 'health',
      component: () => import('./views/medilink/health.vue')
    },
    {
      // 信息中心
      path: '/MediLink/newsCenter',
      name: 'newsCenter',
      component: () => import('./views/medilink/newsCenter.vue')
    },
    {
      // 会员服务
      path: '/MediLink/memberService',
      name: 'memberService',
      component: () => import('./views/medilink/memberService.vue')
    },
    {
      // 紧急救援
      path: '/MediLink/rescueService',
      name: 'rescueService',
      component: () => import('./views/medilink/rescueService.vue')
    },
    {
      // 增值服务
      path: '/MediLink/incrementService',
      name: 'incrementService',
      component: () => import('./views/medilink/incrementService.vue')
    },
    {
      // 视频中心
      path: '/MediLink/videoCenter',
      name: 'videoCenter',
      component: () => import('./views/medilink/videoCenter.vue')
    },
    {
      path: '/MediLink/hospital',
      name: 'hospital',
      component: () => import('./views/medilink/hospital/layout.vue'),
      children: [
        {
          path: 'mainland', //大陆
          name: 'mainland',
          component: () => import('./views/medilink/hospital/mainland.vue')
        },
        {
          path: 'overseas', //海外
          name: 'overseas',
          component: () => import('./views/medilink/hospital/overseas.vue')
        },
        {
          path: 'joinin', //加入我们
          name: 'joinin',
          component: () => import('./views/medilink/hospital/joinin.vue')
        }
      ]
    },
    {
      // Udesk后台管理
      path: '/udesk/manage',
      name: 'udeskManage',
      component: () => import('./views/udesk/manage.vue')
    },
    // 给his系统嵌入使用的页面
    {
      path: '/hiswelfare',
      name: 'hisWelfare',
      meta: {
        breadcrumbName: '福利查询'
      },
      component: () =>
        import(/* webpackChunkName: "his-welfare" */ './views/his-welfare.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLogined()) {
      next({
        name: 'login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
