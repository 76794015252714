import instance from './request'
import { getUserInfo } from '@/utils/authorized'
import qs from 'qs'

const addProviderCode = function(data) {
  const code = (getUserInfo() || {}).providerCode
  if (code) {
    data.providerCode = code
  }
}

let api = {}
api.userLogin = function(data) {
  return instance.post('/login/isValidUserInfo', data)
}
// 退出登录
api.exitLogin = function(data) {
  return instance.get('/login/logOut', data)
}
api.changePwd = function(data) {
  return instance.post('/login/changPassWord', data)
}
api.sendEmail = function(data) {
  return instance.post(`/login/sendMailInfo?${qs.stringify(data)}`, data)
}
// 授权相关
api.authList = function(data) {
  addProviderCode(data)
  return instance.post('/authorization/queryAuthorInfo', data)
}
// 取消预授权
api.cancelPreAuth = function(data) {
  return instance.post('/benefit/cancelPreAuth', data)
}
api.authDetail = function(data) {
  return instance.post('/authorization/queryAuthorDetilById', data)
}
api.exportAuthData = function(data) {
  return instance.post('/authorization/exportData', data, {
    responseType: 'blob'
  })
}
api.printAuthSuccess = function(data) {
  return instance.post('/authorization/print', data)
}

// 就诊记录
api.getMedicialRecordList = function(data) {
  addProviderCode(data)
  return instance.post('/medicial/getRecordList', data)
}
// 就诊记录
api.getMedicialMedicialInfo = function(data) {
  addProviderCode(data)
  return instance.post('/medicial/getMedicialInfo', data)
}
api.submitSettle = function(data) {
  return instance.post('/medicial/employeeDischarge', data)
}
api.querySettleAbout = function(data) {
  return instance.post('/medicial/getEmDischargeInfo', data)
}
api.cancelClaim = function(data) {
  return instance.post('/medicial/cancelClaimInfo', data)
}
api.queryMedicialDetail = function(data) {
  return instance.get(`/medicial/medicialDetail/${data.claimsId}`)
}
api.searchICD = function(data) {
  return instance.post(
    `/medicial/getMedicialCodeInfoList?${qs.stringify(data)}`,
    data
  )
}
api.getMemberList = function(data) {
  return instance.post('/benefit/getMemberInfo', data)
}
api.exportData = function(data) {
  return instance.post('/medicial/exportData', data, { responseType: 'blob' })
}
api.claimPrint = function(data) {
  return instance.post('/medicial/print', data)
}

// 码表查询
api.getSycRefcd = function(data) {
  return instance.post('/medicial/getSycRefcd', data)
}
// 案件复议提交
api.reconsideration = function(data) {
  return instance.post('/medicial/reconsideration', data)
}

// 导出付费凭条返回HTML
api.claimExport = function(data) {
  return instance.post('/medicial/exportPosHtml', data)
}

// 福利查询
api.queryTableList = function(data) {
  return instance.post('/benefit/getMemberInfo', data)
}

// 获取附件列表
api.getAccessoryRemarkList = function(data) {
  return instance.post('/fileUpload/getAccessoryRemarkList', data)
}

// 获取附件URL地址
api.getSigUrl = function(data) {
  return instance.post(`/fileUpload/getSigUrl?key=${data.filePath}`, data)
}

// 被替换掉了
api.queryDetailById = function(params) {
  return instance.get(`benefit/benefitDetail/${params}`)
}

api.queryCoverageDetailById = function(params) {
  return instance.get(`benefit/getCoverageList/${params.id}`)
}

api.querybenefitDetailByCoverage = function(data) {
  return instance.post('benefit/BenefitGroupList', data)
}

// 获取昂贵医院标识
api.getProviderTypeByMember = function(data) {
  return instance.post('benefit/getProviderTypeByMember', data)
}

api.checkIdCard = function(data) {
  return instance.post('/benefit/checkIdCard', data)
}
// 预授权申请身份验证
api.preAuth = function(data) {
  return instance.post('/benefit/preAuth', data)
}
// 预授权信息更新
api.savaAuthDischargeInfo = function(data) {
  return instance.post('/medicial/savaAuthDischargeInfo', data)
}

api.print = function(data) {
  return instance.post('/benefit/print', data)
}

api.getCaseInfoList = function(data) {
  return instance.post('/benefit/getCaseInfoList', data)
}

//影像操作
//批量生成条形码-zip包下载
api.barCodeForZips = function(data) {
  return instance.post('/barCode/barCodeForZips', data, {
    responseType: 'blob'
  })
}

api.barCodeForJPG = function(data) {
  return instance.post('/barCode/barCodeForJPG', data)
}

//根据案件号查询影像件列表（精确查找）
api.queryEspDocOcrList = function(data) {
  return instance.get(`/ocr/queryEspDocOcrListByClaimNo/${data}`)
}

//. 根据案件号和某个影像件名称这个影像件、影像件上面会员、医院等、费用大项列表
api.queryClaimFeeDetailList = function(data1, data2) {
  return instance.get(
    `/ocr/queryClaimFeeDetailListByPushidAndRefNo/${data1}/${data2}`
  )
}

//编辑ocr费用大项信息
api.editOcrBigDetil = function(data) {
  return instance.post('/ocr/editOcrBigDetil', data)
}

//编辑ocr费用小项信息
api.editChildClaimFee = function(data) {
  return instance.post('/ocr/editChildClaimFee', data)
}

//获取费用列表
api.getbenefitList = function(data) {
  return instance.post('/ocr/getbenefitList', data)
}

// 预授权上传资料
api.uploadFile = function(data) {
  return instance.post('/fileUpload/medicalRecord', data)
}
// 上传病历和费用清单
api.uploadFileOfClaim = function(data) {
  return instance.post('/fileUpload/expense', data)
}
api.uploadInvoiceFileOfClaim = function(data) {
  return instance.post('/fileUpload/invoice', data)
}
// 未收件上传资料
api.uploadImageFiles = function(data) {
  return instance.post('/fileUpload/uploadImageFiles', data)
}
/* 
1、就诊记录获取补件说明 {explainType: [JZ|FP],}
2、获取事先授权的补件说明 {explainType: AU}
*/
api.queryFileUploadDesc = function(data) {
  return instance.post('/fileUpload/fleExplain', data)
}
api.batchPrint = function(data) {
  return instance.post('/medicial/batchPrint', data)
}
// 理赔材料上传
api.batchUploadImages = function(data) {
  return instance.post('/medicial/batchUploadImages', data, {
    timeout: 5 * 60 * 1000
  })
}
// /resource/getResourceByUserId
api.getResourceByUserId = function() {
  return instance.post('/resource/getResourceByUserId')
}
// 根据字典代码查询性质类别
api.getReferenceInfo = function(data) {
  return instance.post(`/wx/getReferenceInfo?${qs.stringify(data)}`)
}
// 查询省数据
api.getProviderListToWeb = function(data) {
  return instance.post(`/wx/getProviderListToWeb?${qs.stringify(data)}`)
}
// 查询医疗机构列表
api.getProviderPageListToWeb = function(data) {
  return instance.post(
    `/wx/getProviderPageListToWeb?${qs.stringify(data)}`,
    data
  )
}
// 查询海外医疗机构列表
api.searchCountryForProviderList = function() {
  return instance.post(`/wx/searchCountryForProviderList`, {})
}
api.searchProviderListByCountry = function(data) {
  return instance.post(`/wx/searchProviderListByCountry`, data)
}
//发送邮件
api.sendMailToWeb = function(data) {
  return instance.post('/wx/sendMailToWeb', data)
}
api.getLang = function(data) {
  return instance.get('/user/getLanguage', data)
}
api.changeLang = function(data) {
  return instance.post('/login/changLanguage', data)
}
api.getProviderList = function(data) {
  return instance.post('/medicial/getProviderList', data)
}
api.changeProvide = function(data) {
  return instance.get(`/login/changeProvide?${qs.stringify(data)}`)
}

// web 接口 start

api.goLoginInfo = function(data) {
  return instance.post(`/web/goLoginInfo`, data)
}

api.logOut = function(data) {
  return instance.get('/web/auth/logOut', data)
}

api.resetPwd = function(data) {
  return instance.post(`/web/resetPwd`, data)
}

api.updatePwd = function(data) {
  return instance.post(`/web/auth/updatePwd`, data)
}

api.webPersonCenterInfo = function(data) {
  return instance.post(`/web/auth/personCenterInfo`, data)
}

api.queryMessAges = function(data) {
  return instance.post(`/web/auth/messages/queryMessAges`, data)
}

api.queryEffectivePolicy = function(data) {
  return instance.post(
    `/web/auth/queryEffectivePolicy?${qs.stringify(data)}`,
    data
  )
}

api.queryHisPolicy = function(data) {
  return instance.post(`/web/auth/queryHisPolicy?${qs.stringify(data)}`, data)
}

api.showEmployeeInfo = function(data) {
  return instance.post(`/web/auth/showEmployeeInfo?${qs.stringify(data)}`, data)
}

api.bindCardInfo = function(data) {
  return instance.post(`/web/auth/bindCardInfo`, data)
}

api.queryPersonByPolicy = function(data) {
  return instance.post(`/web/auth/queryPersonByPolicy`, data)
}

api.getEntitlementDetail = function(data) {
  return instance.post(
    `/web/auth/benefit/getEntitlementDetail?${qs.stringify(data)}`,
    data
  )
}

api.getPlanDocList = function(data) {
  return instance.post(
    `/web/auth/benefit/getPlanDocList?${qs.stringify(data)}`,
    data
  )
}

api.getPlanDocDetail = function(data) {
  return instance.get(
    `/web/benefit/getPlanDocDetail?${qs.stringify(data)}`,
    data
  )
}

api.getCoverageList = function(data) {
  return instance.post(
    `/web/auth/benefit/getCoverageList?${qs.stringify(data)}`,
    data
  )
}

api.getbenList = function(data) {
  return instance.post(
    `/web/auth/benefit/getbenList?${qs.stringify(data)}`,
    data
  )
}

api.getICalimsInfo = function(data) {
  return instance.post(`/web/auth/getICalimsInfo?${qs.stringify(data)}`, data)
}

api.getcalimAppBaseIfo = function(data) {
  return instance.post(`/web/auth/calimApp/getcalimAppBaseIfo`, data)
}

api.bankCardInfo = function(data) {
  return instance.post(
    `/web/auth/bankInfo/bankCardInfo?${qs.stringify(data)}`,
    data
  )
}

api.unbindbankCardInfo = function(data) {
  return instance.post(
    `/web/auth/bankInfo/unbindbankCardInfo`,
    qs.stringify(data)
  )
}

api.deleteBankCardInfo = function(data) {
  return instance.post(
    `/web/auth/bankInfo/deleteBankCardInfo`,
    qs.stringify(data)
  )
}

api.changebankCardInfo = function(data) {
  return instance.post(
    `/web/auth/bankInfo/changebankCardInfo`,
    qs.stringify(data)
  )
}

api.getBankInfoList = function(data) {
  return instance.post(`/web/auth/bankInfo/getBankInfoList`, qs.stringify(data))
}

api.getAreaInfo = function(data) {
  return instance.post(`/web/auth/memberInfo/getAreaInfo`, data)
}
// 查询省市区
api.getArea = function(data) {
  return instance.post('/web/auth/memberInfo/getArea', data)
}
api.getBrachOfBankInfoList = function(data) {
  return instance.post(
    `/web/auth/bankInfo/getBrachOfBankInfoList`,
    qs.stringify(data)
  )
}

api.bindBankInfo = function(data) {
  return instance.post(`/web/auth/bankInfo/bindBankInfo`, data)
}

api.createIclaims = function(data) {
  return instance.post(`/web/auth/createIclaims`, data)
}

api.getIAmountCalimsInfo = function(data) {
  return instance.post(`/web/auth/getIAmountCalimsInfo`, data)
}

api.getServiceTemplate = function(data) {
  return instance.post(
    `/web/auth/calimApp/getServiceTemplate?${qs.stringify(data)}`,
    data
  )
}

api.getTemplate = function(data) {
  return instance.post(
    `/web/auth/calimApp/getTemplate?${qs.stringify(data)}`,
    data
  )
}

api.getCalimsAppPatchFileList = function(data) {
  return instance.post(`/web/auth/calimApp/getCalimsAppPatchFileList`, data)
}

api.getPrePatchFileType = function(data) {
  return instance.post(`/web/auth/preLicensing/getPrePatchFileType`, data)
}

api.personCenterInfo = function(data) {
  return instance.post(`/web/auth/personCenterInfo`, data)
}

api.updateUserInfo = function(data) {
  return instance.post(`/web/auth/updateUserInfo`, data)
}

api.getAppDetilInfo = function(data) {
  return instance.post(
    `/web/auth/calimApp/getAppDetilInfo?${qs.stringify(data)}`,
    data
  )
}

api.getClaimAppFileList = function(data) {
  return instance.post(`/web/auth/calimApp/getClaimAppFileList`, data)
}

api.claimCancel = function(data) {
  return instance.post(`/web/auth/claimCancel?${qs.stringify(data)}`, data)
}

// 上传身份证正反面
api.uploadIdCardInfo = function(data) {
  return instance.post(`/web/auth/uploadIdCardInfo`, qs.stringify(data))
}

api.getPreAppbjFileList = function(data) {
  return instance.post(`/web/auth/preLicensing/getPreAppbjFileList`, data)
}

api.patch = function(data) {
  return instance.post(`/web/auth/calimApp/patch`, data)
}

api.getWebProviderList = function(data) {
  return instance.post(`/web/auth/getProviderList`, data)
}

api.getFileType = function(data) {
  return instance.post(`/web/auth/calimApp/getFileType`, data)
}

api.getMemberInfo = function(data) {
  return instance.post(`/web/auth/memberInfo/getMemberInfo`, data)
}

api.uploadInfoByForm = function(data) {
  return instance.post(`/web/auth/uploadInfoByForm`, data)
}
// 支付相关接口
api.pay = function(data) {
  return instance.get(
    `/medilinkPay/checkoutCounter/pay?${qs.stringify(data)}`,
    data
  )
}

api.getOrderStatus = function(data) {
  return instance.post(`/medilinkPay/checkoutCounter/getOrderStatus`, data)
}

api.payinfo = function(data) {
  // return instance.get(`/web/payinfo?${qs.stringify(data)}`, data)
  return instance.post(`/web/payinfo`, data)
}

api.getBankCardInfo = function(data) {
  return instance.get(`/medilinkPay/getBankCardInfo?bankCardNumber=${data}`)
}
api.getMyBankCardList = function(data) {
  return instance.get('/medilinkPay/getAgreements', data)
}
api.agreementApply = function(data) {
  return instance.post('/medilinkPay/agreementApply', data)
}
api.applyPay = function(data) {
  return instance.post('/medilinkPay/payApply', data)
}
api.agreementConfirm = function(data) {
  return instance.post('/medilinkPay/agreementConfirm', data)
}
api.reGetPayCode = function(data) {
  return instance.post('/medilinkPay/reSendPaymentSms', data)
}
api.confirmPay = function(data) {
  return instance.post('/medilinkPay/payConfirm', data)
}
api.unbindBankCard = function(data) {
  return instance.post('/medilinkPay/unBindCard', data)
}
// 文件上传
api.uploadMedFile = function(data) {
  return instance.post('/api/uploadMedFile', data)
}
// 预授权进度查询
api.preLicensingQuery = function(data) {
  return instance.post(
    `/web/auth/preLicensing/query?${qs.stringify(data)}`,
    data
  )
}
// 医疗机构获取产品信息
api.getProductOrgInfo = function(data) {
  return instance.post('/web/auth/getProductOrgInfo', data)
}
//根据省查找市
api.getCityInfo = function(data) {
  return instance.post(`/web/auth/getCityInfo?${qs.stringify(data)}`, data)
}
// 医疗机构条件筛选
api.getProviderListM = function(data) {
  return instance.post('/web/auth/getProviderList', data)
}
// 获取医疗机构详情
api.getProviderDeatil = function(data) {
  return instance.post(
    `/web/auth/getProviderDeatil?${qs.stringify(data)}`,
    data
  )
}
// 就医体检评价列表
api.getEvaluateInfoList = function(data) {
  return instance.post(
    `/web/auth/getEvaluateInfoList?${qs.stringify(data)}`,
    data
  )
}
//预授权单证下载
api.getTemplatePre = function(data) {
  return instance.post(
    `/web/auth/preLicensing/getTemplate?${qs.stringify(data)}`,
    data
  )
}
//预授权服务指南下载
api.getServiceTemplatePre = function(data) {
  return instance.post(
    `/web/auth/preLicensing/getServiceTemplate?${qs.stringify(data)}`,
    data
  )
}
//理赔服务指南下载
api.getServiceTemplateClaim = function(data) {
  return instance.post(
    `/web/auth/calimApp/getServiceTemplate?${qs.stringify(data)}`,
    data
  )
}
//预授权撤销申请
api.cancelPreApp = function(data) {
  return instance.post('/web/auth/preLicensing/cancelPreApp', data)
}
//预授权解释
api.getAuthExplain = function(data) {
  return instance.post('/web/auth/preLicensing/getAuthExplain', data)
}
//获取预授权详情
api.getClaimsDetilPre = function(data) {
  return instance.post('/web/auth/preLicensing/getClaimsDetil', data)
}
//获取预授权详情
api.getClaimsDetilPreApp = function(data) {
  return instance.post('/web/auth/preLicensing/getPreAppDetilInfo', data)
}
//获取预授权已上传文件列表
api.getAuthFileList = function(data) {
  return instance.post('/web/auth/preLicensing/getAuthFileList', data)
}
// 生成理赔解释
api.genEOB = function(data) {
  return instance.post(`/web/auth/genEOB?${qs.stringify(data)}`, data)
}
// 自助理赔详情
api.getClaimsDetail = function(data) {
  return instance.post(`/web/auth/getClaimsDetail?${qs.stringify(data)}`, data)
}
// 自助案件详情
api.getAppDetilInfo = function(data) {
  return instance.post(
    `/web/auth/calimApp/getAppDetilInfo?${qs.stringify(data)}`,
    data
  )
}
//切换语言
api.changLanguageL = function(data) {
  return instance.post(`/web/changLanguage`, data)
}
//查询当前语言
api.getLanguageL = function(data) {
  return instance.post(`/web/getLanguage`, data)
}
// 就医体检评价提交
api.getEvaluateInfo = function(data) {
  return instance.post('/web/auth/getEvaluateInfo', data)
}
// 就医记录查询
api.getMediciRecordInfo = function(data) {
  return instance.post('/web/auth/getMediciRecordInfo', data)
}
// 获取授权申请的基本信息 ，上传文件类型
api.getPreAppBaseIfo = function(data) {
  return instance.post('/web/auth/preLicensing/getPreAppBaseIfo', data)
}
//获取预授权诊断内容
api.getDiagnosis = function(data) {
  return instance.post('/web/auth/preLicensing/getDiagnosis', data)
}
// 预授权申请
api.applyPreAuth = function(data) {
  return instance.post('/web/auth/preLicensing/apply', data)
}
// 预授权材料补充
api.preAuthpatch = function(data) {
  return instance.post(`/web/auth/preLicensing/patch`, data)
}
// 预授权补件列表
api.getAuthPatchFileList = function(data) {
  return instance.post('/web/auth/preLicensing/getAuthPatchFileList', data)
}
// 上传身份证正反面
api.uploadIdCard = function(data) {
  return instance.post('/web/auth/uploadIdCardInfo', data)
}
api.getCountryList = function(data) {
  return instance.post(
    `/web/auth/bankInfo/getCountryList?${qs.stringify(data)}`,
    data
  )
}
//获取出发地点距离
api.getBaiduMapInfo = function(data) {
  return instance.post(
    `/api/businessOffer/getBaiduMapInfo?${qs.stringify(data)}`,
    data
  )
}
// web 接口 end
// udesk电话识别，根据手机号获取会员信息
api.getEmployeesByPhone = function(data) {
  return instance.get(`/api/udeskApi/getEmployees/${data}`)
}
//将信息更新到Udesk
api.updateCustomer = function(data) {
  return instance.post(`/api/udeskApi/updateCustomer`, data)
}

//获取标签列表
api.getUdeskTagList = function(data) {
  return instance.post(`/api/udeskApi/getTagList`, data)
}

//获取通话列表
api.getUdeskCallLogs = function(params, data) {
  return instance.post(
    `/backstage/udesk/getUdeskCallLogs?${qs.stringify(params)}`,
    data
  )
}
//更新通话列表
api.updateUdeskCallLogs = function(data) {
  return instance.post(`/backstage/udesk/updateUdeskCallLogs`, data)
}
//获取禁止提醒列表
api.getUdeskDisableCalls = function(params, data) {
  return instance.post(
    `/backstage/udesk/getUdeskDisableCalls?${qs.stringify(params)}`,
    data
  )
}
//新增禁止提醒列表
api.insertUdeskDisableCall = function(data) {
  return instance.post(`/backstage/udesk/insertUdeskDisableCall`, data)
}
//更新禁止提醒列表
api.updateUdeskDisableCall = function(data) {
  return instance.post(`/backstage/udesk/updateUdeskDisableCall`, data)
}

// 提交预约信息
api.submitAppoint = function(data) {
  return instance.post('/web/auth/appointment', data)
}
// 预约信息列表
api.appointList = data => {
  return instance.post(
    `/web/auth/appointmentList?memberControlNo=${data.memberControlNo}&language=${data.language}`
  )
}

// 获取预约详情的接口
api.getAppointDetail = data => {
  return instance.post(
    `/web/auth/appointmentDetail?queryno=${data.queryno}&language=${data.language}`
  )
}

// 取消预约信息
api.CancelAppoint = queryno => {
  return instance.post(`/web/auth/appointmentCancel?queryno=${queryno}`)
}

// 取消预约信息
api.CancelAppoint = queryno => {
  return instance.post(`/web/auth/appointmentCancel?queryno=${queryno}`)
}

// 获取UDESK在线客服URL
api.getUdeskUrl = data => {
  return instance.post('/wx/auth/getUdeskUrl', data)
}

// 福利查询详情信息-共享限额数据查询
api.getCommonLimits = data => {
  return instance.post('/benefit/getSharedLimits', data)
}

// 查询影像件批量上传列表
api.queryOrcBatchUploadList = data => {
  return instance.post('/fileParse/queryBatchUploadList', data)
}

// 查询影像件批量上传列表 - 导出
api.exportOrcUploadList = data => {
  return instance.post('/fileParse/exportUploadList', data, {
    responseType: 'blob'
  })
}

// 给失败文件录入claimId
api.setFileClaimId = data => {
  return instance.post('/fileParse/setFileClaimId', data)
}

// 案件批次
// 查询未关联批次案件列表
api.queryClaimsList = function(data) {
  addProviderCode(data)
  return instance.post('/batchReport/queryClaimsList', data)
}
// 查询批次关联案件列表
api.queryBatchClaimsList = function(data) {
  addProviderCode(data)
  return instance.post('/batchReport/queryBatchClaimsList', data)
}
// 查询批次列表
api.queryBatchReportList = function(data) {
  addProviderCode(data)
  return instance.post('/batchReport/queryBatchReportList', data)
}
// 查询批次关联案件列表
api.queryBatchClaimsList = function(data) {
  addProviderCode(data)
  return instance.post('/batchReport/queryBatchClaimsList', data)
}
// 创建批次
api.createBatchReport = function(data) {
  return instance.post('/batchReport/createBatchReport', data)
}

// 批次删除关联账单
api.removeClaimsFromBatch = function(data) {
  return instance.post('/batchReport/removeClaimsFromBatch', data)
}

// 批次添加关联账单
api.addClaimsToBatch = function(data) {
  return instance.post('/batchReport/addClaimsToBatch', data)
}

// 导出
api.batchReportExportData = function(data) {
  return instance.post('/batchReport/exportData', data, {
    responseType: 'blob'
  })
}

// 删除批次

api.removeBatchReport = function(data) {
  return instance.post('/batchReport/removeBatchReport', data)
}
// 确认折扣

api.confirmBatchDiscount = function(data) {
  return instance.post('/batchReport/confirmBatchDiscount', data)
}

// 确认批次
api.confirmBatch = function(data) {
  return instance.post('/batchReport/confirmBatch', data)
}

// 优惠券模块开始
// 根据用户获取卡券列表
api.getCouponsList = function(data) {
  return instance.post(
    `/api/getCouponsList?activeFlag=${data.activeFlag}&title=${data.title}&couponsNo=${data.couponsNo}&startDate=${data.startDate}&endDate=${data.endDate}`
  )
}
// 核销卡券信息
api.verifyConpons = function(data) {
  return instance.post(`/api/verifyConpons?id=${data.id}`)
}
// 判断是否可以立案
api.checkProvider = function(data) {
  return instance.post(`/benefit/checkProvider`, data)
}

// his使用嵌入福利查询 -- start
// 福利查询
api.hisQueryWelfareTableList = function(data) {
  return instance.post('/his/getMemberInfo', data, {
    headers: {
      providerCode: data.providerCode
    }
  })
}
api.hisQueryCoverageDetailById = function(params) {
  return instance.get(`/his/getCoverageList/${params.id}`, {
    headers: {
      providerCode: params.providerCode
    }
  })
}
// 福利查询详情信息-共享限额数据查询
api.hisGetCommonLimits = data => {
  return instance.post('/his/getSharedLimits', data, {
    headers: {
      providerCode: data.providerCode
    }
  })
}
api.hisQuerybenefitDetailByCoverage = function(data) {
  return instance.post('/his/BenefitGroupList', data, {
    headers: {
      providerCode: data.providerCode
    }
  })
}
// 获取昂贵医院标识
api.hisGetProviderTypeByMember = function(data) {
  return instance.post('/his/getProviderTypeByMember', data, {
    headers: {
      providerCode: data.providerCode
    }
  })
}
// his使用嵌入福利查询 -- end

export default api
