<template>
  <div class="comp_">
    <!-- 门诊金额变更 -->
    <a-modal
      :title="
        isSettle
          ? $t('Modals[0]') || '门诊付费结算'
          : $t('Modals[1]') || '门诊金额变更'
      "
      :footer="null"
      wrapClassName="change_cost_modal_"
      v-model="visibleChange"
      width="900px"
    >
      <div class="list_">
        <div class="content_">
          <div class="list_item">
            <div class="mrg_r40">
              {{ $t('cols[0]') || '病人姓名' }}：{{ settleInfo.patientName }}
            </div>
            <div class="mrg_r40">
              {{ $t('cols[1]') || '生日' }}： {{ settleInfo.birthDate }}
            </div>
            <div class="mrg_r40">
              {{ $t('cols[2]') || '性别' }}：{{
                settleInfo.gender == 'F'
                  ? $t('Modals[19]') || '女'
                  : $t('Modals[20]') || '男'
              }}
            </div>
          </div>
          <div class="list_item">
            <div class="mrg_r40">
              {{ $t('cols[3]') || '证件号' }}： {{ settleInfo.idNumber }}
            </div>
            <div class="mrg_r40">
              {{ $t('Modals[3]') || '操作日期' }}：{{ settleInfo.operatDate }}
            </div>
          </div>
          <div class="list_item">
            <div class="mrg_r40">
              {{ $t('cols[7]') || '公司名称' }}：{{ settleInfo.coporation }}
            </div>
            <div class="mrg_r40">APP#：{{ settleInfo.approval }}</div>
          </div>
        </div>
        <div class="head_ patch_">
          <div v-if="notConfirmSettle">
            {{ $t('Modals[4]') || '科室名称' }}：{{ settleInfo.deptName }}
          </div>
          <div v-else>{{ $t('Modals[6]') || '结算信息' }}</div>
        </div>
        <div class="content_">
          <a-form
            v-if="notConfirmSettle"
            :form="changeCostForm"
            @submit="handleSubmitSettle"
          >
            <div class="list_item">
              <div class="mrg_r40 input_wrap_">
                <label style="white-space:nowrap;"
                  >{{ $t('Modals[7]') || '疾病代码' }}（ICD）1：</label
                >
                <a-select
                  showSearch
                  :value="valueOfICD"
                  :placeholder="$t('labels[38]') || '可直接输入ICD代码或关键字'"
                  class="no_border_input_ s_n"
                  :defaultActiveFirstOption="false"
                  :showArrow="false"
                  :filterOption="false"
                  @search="handleSearch"
                  @change="handleICDChange($event, 'valueOfICD')"
                  :notFoundContent="null"
                >
                  <a-select-option
                    v-for="d in dataOfSearchICD"
                    :key="d.value"
                    :disabled="d.disabled"
                    >{{ d.text }}</a-select-option
                  >
                </a-select>
              </div>
            </div>

            <div class="list_item">
              <div class="mrg_r40 input_wrap_">
                <label style="white-space:nowrap;"
                  >{{ $t('Modals[7]') || '疾病代码' }}（ICD）2：</label
                >
                <a-select
                  showSearch
                  :value="icd_code2"
                  :placeholder="$t('labels[38]') || '可直接输入ICD代码或关键字'"
                  class="no_border_input_ s_n"
                  :defaultActiveFirstOption="false"
                  :showArrow="false"
                  :filterOption="false"
                  @search="handleSearch"
                  @change="handleICDChange($event, 'icd_code2')"
                  :notFoundContent="null"
                >
                  <a-select-option
                    v-for="d in dataOfSearchICD"
                    :key="d.value"
                    :disabled="d.disabled"
                    >{{ d.text }}</a-select-option
                  >
                </a-select>
              </div>
            </div>
            <div v-if="isSettle && sickList.length > 0" class="list_item">
              <div class="mrg_r40 mrg_r401">
                {{ $t('Modals[8]') || '常见病' }}
              </div>
              <div class="mrg_r40 list_btn overflow_">
                <div
                  class="btn_"
                  v-for="item in sickList"
                  :key="item.icdCode"
                  :class="[isActiveId == item.icdCode ? 'active_' : '']"
                  @click="handleActive(item.icdCode, 'valueOfICD')"
                >
                  {{ item.nickName }}
                </div>
              </div>
            </div>
            <template v-if="isDentistry">
              <div class="list_wrap_">
                <!-- 费用类型 //1金额2天数3次数4颗数 -->
                <div :key="index" v-for="(item, index) in benefitList">
                  <a-row class="list_item" style="width: 100%;">
                    <a-col style="padding: 8px 0;" :span="10">{{
                      item.benefit_desc
                    }}</a-col>
                    <a-col :span="14">
                      <a-form-item
                        class="item_i"
                        :class="
                          `${item.benefit_catg != 1 ? 'visible_' : 'hidden_'}`
                        "
                        style="margin-right: 52px;"
                      >
                        <a-input
                          v-model="item.benefit_qty"
                          class="no_border_input_ mini_m_"
                          :placeholder="$t('tips[21]') || '请输入'"
                          @change="
                            e => handleMoneyInput(e, item, 'benefit_qty')
                          "
                        ></a-input>
                        {{
                          item.benefit_catg == 4
                            ? $t('Modals[15]') || '颗'
                            : item.benefit_catg == 2
                            ? $t('Modals[16]') || '天数'
                            : item.benefit_catg == 3
                            ? $t('Modals[17]') || '次数'
                            : $t('Modals[5]') || '默认'
                        }}
                      </a-form-item>
                      <a-form-item>
                        <a-input
                          @blur="countSettleSum"
                          v-model="item.amt_incurred"
                          class="no_border_input_ mini_"
                          :placeholder="$t('tips[21]') || '请输入'"
                          @change="e => handleMoneyInput(e, item)"
                        ></a-input
                        >{{ $t('Modals[18]') || '元' }}
                      </a-form-item>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </template>
            <div
              class="head_"
              :style="{
                padding: '14px 0',
                background: 'none',
                fontSize: '17px'
              }"
            >
              <div class="count_wrap_">
                <p>
                  {{ $t('claimDetail[16]') || '总' }}：
                  <span class="num_">{{ settleSum }}</span>
                  {{ $t('Modals[18]') || '元' }}
                </p>
                <p class="sum-tips">请输入折前金额</p>
              </div>
              <div class="h_right_">
                <a-button html-type="submit" type="primary" class="h_right_btn">
                  {{
                    isSettle
                      ? $t('Modals[9]') || '付费结算'
                      : $t('Modals[10]') || '变更'
                  }}
                </a-button>
              </div>
            </div>
          </a-form>
          <div v-else>
            <div v-if="settleIsPass" class="dt_">
              {{ $t('claimDetail[13]') || '费用明细' }}
            </div>
            <div class="dd_">
              <iframe
                style="width: 100%;min-height:300px;"
                v-if="settleIsPass"
                :srcdoc="settleResultHtml"
              ></iframe>
              <div style="text-align: center;" v-else>
                {{ settleResultHtml }}
              </div>
            </div>
            <div style="display: flex;">
              <a-button
                v-if="settleIsPass"
                @click="handlePrint(settleInfo)"
                class="btn_full_"
                type="primary"
                >{{ $t('labels[39]') || '打印付费结算凭条' }}</a-button
              >
              <a-button
                v-if="settleIsPass"
                @click="handleExport(settleInfo)"
                class="btn_full_"
                type="primary"
                >{{ $t('labels[56]') || '导出付费结算凭条' }}</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 门诊金额变更 -->

    <!-- 模态框3 -->
    <a-modal
      :title="$t('btns[11]') || '上传资料'"
      :footer="null"
      wrapClassName="myModal_"
      v-model="visibleUpload"
    >
      <div class="list_">
        <div class="content_">
          <div class="list_item">
            <div class="mrg_r40">
              {{
                $t('labels[40]') || '请提供病历及艾滋病和梅毒的检查化验报告单。'
              }}
            </div>
          </div>
          <div class="list_item">
            <div class="mrg_r40">{{ $t('Modals[11]') || '病历' }}：</div>
            <div class="mrg_r40">
              <a-upload
                name="file"
                :multiple="true"
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              >
                <a-button class="upload_btn">{{
                  $t('labels[41]') || '选择文件'
                }}</a-button>
              </a-upload>
            </div>
            <div class="mrg_r40">#29347430247</div>
          </div>
          <div class="list_item">
            <div class="mrg_r40" :style="{ marginRight: '82px' }"></div>
            <div class="mrg_r40">
              <a-upload
                name="file"
                :multiple="true"
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              >
                <a-button class="upload_btn">{{
                  $t('labels[41]') || '选择文件'
                }}</a-button>
              </a-upload>
            </div>
            <div class="mrg_r40">#29347430247</div>
          </div>
          <div class="list_item">
            <div class="mrg_r40">{{ $t('Modals[11]') || '病历' }}：</div>
            <div class="mrg_r40">
              <a-upload
                name="file"
                :multiple="true"
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              >
                <a-button class="upload_btn">{{
                  $t('labels[41]') || '选择文件'
                }}</a-button>
              </a-upload>
            </div>
            <div class="mrg_r40">#29347430247</div>
          </div>
        </div>
        <div
          class="head_"
          :style="{ padding: '14px 0', background: 'none', fontSize: '17px' }"
        >
          <div :style="{ fontWeight: '500' }">
            {{ $t('claimDetail[16]') || '总' }}：3910元
          </div>
          <div class="h_right_">
            <div class="h_right_btn">
              {{ $t('Modals[10]') || '变更' }}
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 模态框3 -->
    <!-- 上传文件 -->
    <UploadModal
      @success="onSuccess"
      v-bind:visible.sync="uploadModalVisible"
      :type="uploadType"
      :uploadDescInfo="uploadDescInfo"
      :claim-id="record.claimsId"
    />
    <!-- 打印 -->
    <a-modal
      :afterClose="beforePrintModalClose"
      @cancel="printModalVisible = false"
      width="950px"
      :footer="null"
      :visible="printModalVisible"
      :title="modalTitle"
    >
      <img ref="container" />
      <div
        id="print-el"
        ref="imageWrapper"
        v-if="printUrl"
        style="width: 900px;"
        :style="`height: ${ifaHeight + 100}px;`"
      >
        <!-- <img width="100%" height="100%" src="../../assets/home_bg.jpg"> -->
        <iframe
          ref="iframe"
          width="100%"
          height="100%"
          :src="printUrl"
        ></iframe>
      </div>
      <div>
        <a-popconfirm
          placement="top"
          :ok-text="$t('common.confirm')"
          :cancel-text="$t('common.cancel')"
          @confirm="print"
        >
          <template slot="title">
            <p style="font-size: 12px; padding: 10px">
              {{ $t('message.upgrade') }}
            </p>
          </template>
          <a-button class="btn_print_" type="primary">{{
            $t('Modals[12]') || '打印'
          }}</a-button>
        </a-popconfirm>
      </div>
    </a-modal>
    <!-- 导出付费结算凭条弹层 -->
    <a-modal
      width="900px"
      :footer="null"
      @cancel="exportModalVisible = false"
      :visible="exportModalVisible"
      class="exportModal"
    >
      <!-- 付费凭条HTML -->
      <div
        id="exportHtml"
        v-html="exportHtml"
        style="width: 860px; height: 900px;"
      ></div>
    </a-modal>

    <!-- 发起复议 -->
    <a-modal
      @cancel="reconsiderVisibel = false"
      :footer="null"
      :visible="reconsiderVisibel"
      :title="$t('btns[19]') || '发起复议'"
    >
      <a-form layout="horizontal" :form="reconsiderForm" @submit="handleSubmit">
        <a-form-item :label="$t('labels[57]') || 'Reconsideration Reason'">
          <a-select
            v-decorator="[
              'reconsiderationReason',
              { rules: [{ required: true }] }
            ]"
            :placeholder="$t('uploadModal.select')"
            style="border: 1px solid #d9d9d9;"
          >
            <a-select-option
              v-for="item in reconsiderationReasonCode"
              :key="item.refcd"
              :value="item.refcd"
              >{{ item.fdesc }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-row>
          <a-form-item :label="$t('labels[58]') || 'Private Remark'">
            <a-textarea
              v-decorator="['privateRemark']"
              :placeholder="$t('uploadModal.input')"
              :auto-size="{ minRows: 6, maxRows: 12 }"
              :maxLength="500"
            />
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item class="text-center">
            <a-button type="primary" html-type="submit">{{
              $t('tips[17]') || '保存'
            }}</a-button>
          </a-form-item>
        </a-row>
      </a-form>
    </a-modal>

    <!-- <a-spin class="loading_" size="large" :spinning="spinning"></a-spin> -->
  </div>
</template>

<script>
import commonMixin from '@/mixins/common'
import UploadModal from './UploadModal'
import getPdf from '@/utils/htmlToPdf'
import Bus from '@/utils/Bus'

let timeout
let currentValue
const MESSAGE_CHANNEL = 'modal-message'

export default {
  data() {
    return {
      modalTitle: this.$t('Modals[12]') || '打印',
      settleResultHtml: undefined,
      settleIsPass: false,
      printUrl: undefined,
      visibleChange: false,
      changeCostForm: this.$form.createForm(this),
      settleSum: 0,
      dataOfSearchICD: [],
      valueOfICD: undefined,
      icd_code2: undefined,
      isDentistry: true, // 是否是牙科
      notConfirmSettle: true,
      isSettle: true,
      printModalVisible: false,
      uploadModalVisible: false,
      isActiveId: 1,
      sickList: [],
      settleInfo: {},
      benefitList: [],
      visibleUpload: false,
      ifaHeight: 1200,
      record: {},
      uploadType: undefined,
      uploadDescInfo: {},
      imgUrl: '',
      imgShow: false,
      exportHtml: '', // 导出付费结算凭条HTML
      exportModalVisible: false,
      // 发起复议数据
      claimsId: '',
      reconsiderVisibel: false,
      reconsiderForm: this.$form.createForm(this),
      reconsiderFormData: {},
      reconsiderationReasonCode: []
    }
  },
  mixins: [commonMixin],
  components: {
    UploadModal
  },
  created() {
    Bus.$off(MESSAGE_CHANNEL)
    Bus.$on(MESSAGE_CHANNEL, options => {
      if (options.title) {
        this.modalTitle = options.title
      }
      if (options.onSuccess) {
        this._onSuccess = options.onSuccess
      }
      if (options.type == 'handlePrint') {
        this.printType = options.printType
        this.handlePrint(options.data)
      } else if (options.type == 'handleToSettle') {
        this.handleToSettle(options.isSettle, options.data)
      } else if (options.type == 'showConfirm') {
        this.showConfirm(options.data)
      } else if (options.type == 'handleUpload') {
        this.handleUpload(options.data)
        this.uploadType = options.uploadType
      } else if (options.type == 'showReconsider') {
        this.showReconsider(options.data)
        this.getSycRefcd()
      }
      // 预授权相关
      if (options.type == 'printAuthSuccess') {
        this.printType = options.printType
        this.printAuthSuccess(options.data.claimsId)
      } else if (options.type == 'handleAuthUpload') {
        this.handleAuthUpload(options.data)
        this.uploadType = 1
      }
    })
  },
  methods: {
    beforePrintModalClose() {},
    onSuccess() {
      this._onSuccess()
    },
    handleMoneyInput(e, item, key) {
      let val = e.target.value
      let value = ''
      try {
        value = val.replace(/[^\d^\.]+/g, '').replace(/(\.\d\d)\d+$/, '$1')
      } catch (error) {
        console.log(error)
      }
      if (key) {
        item[key] = value
      } else {
        item.amt_incurred = value
      }
    },
    queryFileUploadDesc(params) {
      return this.$apis
        .queryFileUploadDesc(params)
        .then(res => res.data)
        .then(data => {
          this.uploadDescInfo = data.data
        })
    },
    handleUpload(record) {
      // 未收件类型 上传资料
      if (['04', '10'].includes(record.appLoading)) {
        this.uploadModalVisible = true
        this.record = record
        this.uploadDescInfo = {
          payRemarks: '请上传发票/其他文件',
          list: [
            {
              typeCode: 'FB',
              typeName: '发票'
            },
            {
              typeCode: 'OT',
              typeName: '其他文件'
            }
          ]
        }
        return
      }
      this.queryFileUploadDesc({
        claimsId: record.claimsId,
        explainType: this.uploadType == 1 ? 'JZ' : 'FP'
      }).then(() => {
        this.uploadModalVisible = true
        this.record = record
      })
    },
    handleActive(id) {
      // 结算弹层 - 切换
      this.isActiveId = id
      this.handleICDChange(id)
    },
    handleToSettle(isSettle, record) {
      this.showSpin()
      this.settleSum = 0
      // 清空疾病代码
      this.valueOfICD = undefined
      this.dataOfSearchICD = []
      this.fetchSettleAbout(record.claimsId)
        .then(data => {
          const {
            medicialTypeList,
            benefitList,
            icdCode,
            description
          } = data.data
          if (icdCode) {
            this.valueOfICD = icdCode
            this.dataOfSearchICD = [
              {
                value: icdCode,
                text: description
              }
            ]
          }
          this.isSettle = isSettle // 是否结算还是变更
          this.visibleChange = true
          this.notConfirmSettle = true
          this.benefitList = benefitList

          this.countSettleSum()

          this.sickList = medicialTypeList.filter(item => {
            return item.nickName !== ''
          })
          this.settleInfo = data.data
        })
        .finally(() => {
          this.hideSpin()
        })
    },
    countSettleSum() {
      // 计算总保费
      let total = 0
      this.benefitList.forEach(item => {
        if (item.amt_incurred && /\d+/.test(item.amt_incurred)) {
          total += parseFloat(item.amt_incurred)
        }
      })
      this.settleSum = total.toFixed(2)
    },
    print() {
      window.open(this.printUrl)
    },
    fetchSettleAbout(claimsId) {
      // 查询结算相关信息
      return this.$apis
        .querySettleAbout({
          calimsId: claimsId,
          meFlag: this.isSettle ? '1' : '2' // 标志 1 门诊付费计算 2 金额变更详情
        })
        .then(res => res.data)
    },
    showConfirm(record) {
      this.$confirm({
        iconType: 'exclamation-circle',
        title:
          this.$t('p[1]') ||
          '取消理赔后，视该理赔为无效记录，您将无法进行任何，是否继续操作取消？',
        // content: h => <div style="color:red;">Some descriptions</div>,
        cancelText: this.$t('btns[8]') || '确认取消',
        okText: this.$t('btns[9]') || '不了，保持原状态',
        onCancel: () => {
          this.cancelClaim(record)
        },
        class: 'test'
      })
    },
    cancelClaim(record) {
      const { cardNo, claimsId } = record
      this.$apis
        .cancelClaim({
          cardNo,
          claimsId
        })
        .then(res => res.data)
        .then(() => {
          this.$message.success(this.$t('tips[34]') || '取消成功')
          this._onSuccess()
        })
    },
    fetchICDByKeyword(value, callback) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      currentValue = value

      timeout = setTimeout(() => {
        this.$apis
          .searchICD({
            keyWord: value
          })
          .then(res => res.data)
          .then(data => {
            if (currentValue === value) {
              const result = data.data
              let arr = []
              result.forEach(r => {
                arr.push({
                  value: r.icdCode,
                  text: r.description
                })
              })
              if (arr.length < 1) {
                arr = [
                  {
                    value: '',
                    text: this.$t('tips[35]') || '未查询到此疾病',
                    disabled: true
                  }
                ]
              }
              callback(arr)
            }
          })
      }, 300)
    },
    handleSearch(value) {
      if (value.length >= 2) {
        this.fetchICDByKeyword(value, data => (this.dataOfSearchICD = data))
      }
    },
    handleICDChange(value, prop) {
      this[prop] = value
      this.fetchICDByKeyword(value, data => (this.dataOfSearchICD = data))
    },
    handleSubmitSettle(e) {
      e && e.preventDefault()
      if (!this.settleSum) {
        this.$message.error(this.$t('tips[36]') || '请输入费用')
        return
      }
      if (!this.valueOfICD) {
        this.$message.error(this.$t('tips[37]') || '请选择疾病')
        return
      }
      const benefitList = this.benefitList.filter(item => {
        return item.amt_incurred && item.amt_incurred > 0
      })
      const {
        admissionDate,
        cardNo,
        claimsId,
        coverageCode,
        deptName,
        terminalId
      } = this.settleInfo
      const params = {
        benefitList,
        due_total: this.settleSum,
        flag: this.isSettle ? 1 : 2,
        icd_code: this.valueOfICD,
        icd_code2: this.icd_code2,
        admission_date: admissionDate,
        card_no: cardNo,
        claims_id: claimsId,
        coverage_code: coverageCode,
        dep_name: deptName,
        terminalid: terminalId
      }
      this.$apis
        .submitSettle({
          ...params
        })
        .then(res => res.data)
        .then(data => {
          if (data.data) {
            const { Pass, HtmlText, MessageText } = data.data
            this.settleResultHtml = HtmlText || MessageText
            this.settleIsPass = Pass == 'N' ? false : true
            this.notConfirmSettle = false
          } else {
            this.$message.info(this.$t('tips[38]') || '接口数据返回异常')
          }
        })
    },
    handlePrint(record) {
      if (record.batchPrint) {
        let aEl = document.createElement('a')
        aEl.target = '_blank'
        aEl.href = record.printUrl
        aEl.click()
        return
      }
      this.showSpin()
      if (!this.printType) {
        this.printType = 'POS40'
      }
      this.$apis
        .claimPrint({
          claimsId: record.claimsId,
          printType: this.printType || 'POS40'
        })
        .then(res => res.data)
        .then(data => {
          this.printUrl = data.data.printUrl
          this.printModalVisible = true
        })
        .finally(() => {})
    },
    // 导出付费结算凭条
    handleExport(record) {
      this.$apis
        .claimExport({
          claimsId: record.claimsId,
          printType: this.printType || 'POS40'
        })
        .then(res => res.data)
        .then(data => {
          this.exportHtml = data
          this.exportModalVisible = true
          Bus.$emit('show-loading')
          this.$nextTick(() => {
            getPdf('付费凭条', '#exportHtml', () => {
              this.exportModalVisible = false
              Bus.$emit('hide-loading')
            })
          })
        })
        .finally(() => {})
    },
    printAuthSuccess(id) {
      // 预授权相关
      return this.$apis
        .printAuthSuccess({
          claimsId: id,
          printType: this.printType
        })
        .then(res => res.data)
        .then(data => {
          this.printUrl = data.data.printUrl
          this.printModalVisible = true
        })
    },
    handleAuthUpload(record) {
      this.queryFileUploadDesc({
        claimsId: record.claimsId,
        explainType: 'AU'
      }).then(() => {
        this.uploadModalVisible = true
        this.record = record
      })
    },

    getSycRefcd() {
      if (this.reconsiderationReasonCode.length) return
      this.$apis
        .getSycRefcd({
          modid: 'ES',
          refgrp: 'RECONSIDERATION_REASON'
        })
        .then(res => res.data)
        .then(data => {
          if (data.code === 200) {
            this.reconsiderationReasonCode = data.data
          }
        })
    },

    showReconsider(record) {
      this.claimsId = record.claimsId
      this.reconsiderVisibel = true
    },

    // 发起复议提交
    handleSubmit(e) {
      e && e.preventDefault()
      this.reconsiderForm.validateFieldsAndScroll(
        { scroll: { offsetTop: 100, offsetBottom: 100 } },
        (error, values) => {
          if (error) {
            return false
          }
          const reqData = {
            ...values,
            claimsId: this.claimsId
          }

          this.$apis
            .reconsideration(reqData)
            .then(res => res.data)
            .then(data => {
              if (data.code === 200) {
                this.$message.success(data.msg || '保存成功')
                this.$emit('onSuccess')
                this.reconsiderVisibel = false
              } else {
                this.$message.error(data.msg || '保存出错')
              }
            })
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.horizontal_form_ {
  &.item_line_ {
    .ant-form-item {
      border-bottom: 1px solid #eff1f5;
      margin-bottom: 0;
      padding: 12px 0;
    }
  }
}

.upload_btn {
  background: rgba(244, 245, 246, 1);
  border-radius: 6px;
}

.active_ {
  background: #00bd59 !important;
  color: #fff;
}
.change_cost_modal_ {
  width: 1000px;
  .ant-form-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0px;
  }

  .content_ {
    .dt_ {
      width: 100%;
      line-height: 18px;
      background: #eff1f5;
      text-align: center;
    }
    .dd_ {
      white-space: pre-wrap;
    }
    .btn_full_ {
      display: block;
      width: 100%;
      height: 36px;
      margin: 0 10px;
    }
  }
  .count_wrap_ {
    display: flex;
    align-items: flex-end;
    font-size: 10px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    .num_ {
      font-size: 19px;
      font-family: DIN;
      font-weight: bold;
      color: #333333;
      margin-right: 8px;
    }
    .sum-tips {
      color: red;
      margin-left: 10px;
    }
  }
}
.list_wrap_ {
  max-height: 260px;
  overflow-y: auto;
}
.visible_ {
  visibility: visible;
}
.hidden_ {
  visibility: hidden;
}

.text-center {
  text-align: center;
}

.btn_print_ {
  display: block;
  margin: 30px auto 0;
  width: 124px;
  height: 36px;
}

.overflow_ {
  overflow-x: auto;
  height: 85%;
  width: 85%;
}
.overflow_::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
}
.overflow_::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 2px #05be5c;
  background: #05be5c;
}

.input_wrap_ {
  display: flex;
  width: 100%;
  line-height: 32px;
}
.no_border_input_ {
  flex: 1;
  border: none;
  box-shadow: none;
  &:hover,
  &:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
  &.mini_ {
    width: 90px;
    display: inline-block;
  }
  &.mini_m_ {
    width: 66px;
  }
}
.list_ {
  color: rgba(38, 38, 38, 1);
  font-family: Source Han Sans SC;
  width: 100%;
  font-size: 14px;
  margin-bottom: 22px;
  .head_ {
    padding: 14px 0;
    height: 20%;
    display: flex;
    background: #f6f7f9;
    justify-content: space-between;
    align-items: center;
    &.patch_ {
      padding: 0;
      margin: 14px 0;
      padding-left: 9px;
      border-left: 3px solid #05be5c;
      background: #fff;
      font-size: 13px;
      color: #333333;
    }
    .h_right_ {
      display: flex;
      .h_right_btn {
        width: 198px;
        height: 36px;
        background: rgba(0, 189, 89, 1);
        border: 1px solid rgba(0, 189, 89, 1);
        border-radius: 3px;
        font-size: 10px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .content_ {
    .list_item {
      height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid rgba(233, 237, 244, 1);
      .mrg_r40 {
        margin-right: 40px;
      }
      .mrg_r401 {
        width: 50px;
      }
      .flex_1 {
        flex: 1;
      }
      .flex_3 {
        flex: 3;
      }
      .colorGray {
        color: #cacacc;
      }
    }
    .list_item_2 {
      height: 180px;
    }
    .list_btn {
      display: flex;
      align-items: center;
      .btn_ {
        cursor: pointer;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(234, 235, 237, 1);
        border-radius: 6px;
        padding: 0px 10px;
        margin-right: 10px;
        white-space: nowrap;
      }
    }
    .listItem_left {
      height: 100%;
      border-right: 1px solid rgb(233, 237, 244);
      display: flex;
      align-items: center;
    }
    .paddLeft_32 {
      padding-left: 32%;
      display: inline-block;
    }
  }
}
</style>
