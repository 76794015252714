<template>
  <div class="page_ page_welfare_">
    <WelfareDetail
      v-if="isShow"
      :data="detailData"
      :isHis="true"
      :providerCode="providerCode"
    />
    <div class="tip" v-else>未查到</div>
  </div>
</template>

<script>
import Bus from '@/utils/Bus'
import commonMixin from '@/mixins/common'
import authorizedMixin from '@/mixins/authorized'

export default {
  data() {
    return {
      isShow: false,
      detailData: {}
    }
  },
  mixins: [commonMixin, authorizedMixin],
  mounted() {
    this.cardNo = this.$route.query.cardNo || ''
    this.providerCode = this.$route.query.providerCode || ''
    if (this.cardNo && this.providerCode) {
      this.queryTableList()
    }
  },
  methods: {
    queryTableList() {
      this.$apis
        .hisQueryWelfareTableList({
          pageNo: 1,
          pageSize: 10,
          cardNo: this.cardNo,
          providerCode: this.providerCode
        })
        .then(res => {
          if (res.data.data.benefitShowVoList.length < 2) {
            this.detailData = res.data.data.benefitShowVoList[0] || {}
            Bus.$emit('empPlanId', {
              data: this.detailData
            })
            this.isShow = true
          } else {
            this.isShow = false
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.page_welfare_ {
  padding: 0 10px 10px;
}
.tip {
  text-align: center;
  font-size: 16px;
  margin-top: 40px;
  font-weight: bold;
}
</style>
