<template>
  <div class="login_bg_">
    <div class="top_header_">
      <img class="logo_" src="../assets/logo.png" />
      <label class="com_">
        {{ $t('title') || '中间带(北京)技术服务有限公司' }}</label
      >
      <div class="lang_">
        <span @click="changeLanguage">{{
          $i18n.locale == 'zh-cn' ? 'English' : '中文'
        }}</span>
      </div>
    </div>
    <div>
      <div class="left_wrap_">
        <div class="line_"></div>
        <div class="title_">
          {{ $t('login.a') || '欢迎登录医疗机构管理平台' }}
        </div>
        <div>
          <ul class="list_">
            <li>{{ $t('login.b') || '强大的数据归纳能力' }}</li>
            <li>{{ $t('login.c') || '专业的医疗机构管理平台' }}</li>
          </ul>
        </div>
        <div class="line"></div>
        <div class="copyright_ fs9_">
          © 2014-2018 Insgeek.com All Rights Reserved 京ICP备 15030724 号
        </div>
        <div class="fs9_">
          {{ $t('login.d') || '备案号'
          }}<a
            style="color:white;curser:ponter"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            >&nbsp; 京ICP08007346号-1</a
          >
        </div>
      </div>
      <div class="login_container_">
        <div class="login_title_">
          <ul class="tabs_">
            <li @click="switchTab(0)" :class="activeTab == 0 ? 'active_' : ''">
              {{ $t('login.dl') || '登录' }}
            </li>
            <li @click="switchTab(1)" :class="activeTab == 1 ? 'active_' : ''">
              {{ $t('login.wj') || '忘记密码' }}
            </li>
          </ul>
        </div>
        <a-form
          v-if="activeTab == 1"
          class="login_form_"
          :form="pwdForm"
          @submit="handleChangePwdSubmit"
        >
          <a-form-item style="margin-bottom: 0;">
            <a-input
              v-decorator="[
                'userName',
                {
                  rules: [{ required: true, message: '请输入用户名' }]
                }
              ]"
              :placeholder="$t('login.yhm') || '请输入用户名'"
              size="large"
            >
              <a-icon
                slot="suffix"
                type="user"
                style="color: rgba(0,0,0,.25)"
              />
            </a-input>
          </a-form-item>
          <div :class="`error_ ${hasError ? 'show_' : 'hide_'}`">
            {{ errorMsg }}
          </div>
          <div class="steps_wrap_">
            <ul>
              <li>
                <i class="step_">1</i>
                <div class="desc_01_">
                  {{ $t('login.e') || '填写注册用户名 发送重置密码邮件' }}
                </div>
              </li>
              <li><i class="arrow_"></i></li>
              <li>
                <i class="step_">2</i>
                <div class="desc_02_">
                  {{ $t('login.g') || '点击链接\n设置新密码' }}
                </div>
              </li>
            </ul>
          </div>
          <a-form-item>
            <a-button
              size="large"
              type="primary"
              html-type="submit"
              :disabled="submitDisabled"
              class="login-form-button"
            >
              {{ $t('login.h') || '发送密码重置邮件' }}
            </a-button>
          </a-form-item>
        </a-form>
        <a-form
          v-else
          id="components-form-demo-normal-login"
          :form="form"
          class="login_form_"
          @submit="handleSubmit"
        >
          <a-form-item>
            <a-input
              v-decorator="[
                'userName',
                {
                  rules: [{ required: true, message: '请输入用户名!' }]
                }
              ]"
              :placeholder="$t('login.yhm') || '请输入用户名'"
              size="large"
            >
              <a-icon
                slot="suffix"
                type="user"
                style="color: rgba(0,0,0,.25)"
              />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              v-decorator="[
                'password',
                {
                  rules: [
                    { required: true, message: $t('login.mm') || '请输入密码' }
                  ]
                }
              ]"
              type="password"
              :placeholder="$t('login.mm') || '请输入密码'"
              size="large"
            >
              <a-icon
                slot="suffix"
                type="lock"
                style="color: rgba(0,0,0,.25)"
              />
            </a-input>
          </a-form-item>
          <a-form-item style="margin-bottom: 0;">
            <a-input
              v-decorator="[
                'verificationCode',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('login.yzm') || '请输入验证码'
                    }
                  ]
                }
              ]"
              :placeholder="$t('login.yzm') || '请输入验证码'"
              size="large"
            >
              <!-- <a-icon slot="suffix" type="lock" style="color: rgba(0,0,0,.25)" /> -->
            </a-input>
            <img
              class="veri_code_"
              @click="changeImgCodeUri"
              :src="imgCodeUri"
            />
          </a-form-item>
          <div :class="`error_ ${hasError ? 'show_' : 'hide_'}`">
            {{ errorMsg }}
          </div>
          <a-form-item>
            <a-button
              size="large"
              type="primary"
              html-type="submit"
              class="login-form-button"
            >
              {{ $t('login.dl') || '立即登录' }}
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { setLogined, setLang } from '@/utils/authorized'
import encrypt from '@/utils/crypt'
import moment from 'moment'

export default {
  data() {
    return {
      errorMsg: '',
      hasError: false,
      pwdForm: this.$form.createForm(this),
      activeTab: 0,
      imgCodeUri: `${
        process.env.NODE_ENV === 'production' ? '/api' : '/api_portal'
      }/login/defaultKaptcha?rand=${Math.random()}`,
      submitDisabled: false
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this)
    this.$apis.getLang().then(res => res.data)
  },
  mounted() {
    this.changeImgCodeUri()
  },
  methods: {
    saveLang(langNum) {
      const lang = langNum == '01' ? 'zh-cn' : 'en-us'
      setLang(lang)
      moment.locale(lang)
      this.$i18n.locale = lang
    },
    changeLanguage() {
      let lang = 'zh-cn'
      let langNum = '01'
      if (this.$i18n.locale == 'en-us') {
        this.$i18n.locale = 'zh-cn'
      } else {
        this.$i18n.locale = 'en-us'
        lang = 'en-us'
        langNum = '02'
      }
      setLang(lang)
      moment.locale(lang)
      this.$apis
        .changeLang({
          language: langNum
        })
        .then(res => res.data)
    },
    handleChangePwdSubmit(e) {
      e && e.preventDefault()
      this.submitDisabled = true
      let st = setTimeout(() => {
        clearTimeout(st)
        this.submitDisabled = false
      }, 60 * 1000)
      this.pwdForm.validateFields((error, values) => {
        if (!error) {
          this.$apis
            .sendEmail({
              ...values
            })
            .then(res => res.data)
            .then(() => {
              this.hasError = false
              this.$message.success(
                this.$t('tips[41]') || '已发送邮件 60秒后可再次发送'
              )
            })
            .catch(err => {
              this.errorMsg = err.data.msg || ''
              this.hasError = true
              this.submitDisabled = false
              clearTimeout(st)
            })
        }
      })
    },
    switchTab(val) {
      this.activeTab = val
      this.hasError = false
    },
    changeImgCodeUri() {
      this.imgCodeUri = `${
        process.env.NODE_ENV === 'production' ? '/api' : '/api_portal'
      }/login/defaultKaptcha?rand=${Math.random()}`
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$apis
            .userLogin({
              ...values,
              password: encrypt(values.password)
            })
            .then(res => res.data)
            .then(() => {
              this.hasError = false
              setLogined(1)
              this.$router.push('/dashboard')
            })
            .catch(error => {
              if (error.data.code && error.data.code == '202') {
                this.changeImgCodeUri()
              }
              this.errorMsg = error.data.msg || ''
              this.hasError = true
            })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.error_ {
  color: red;
  line-height: 24px;
  height: 24px;
  &.hide_ {
    visibility: hidden;
  }
  &.show_ {
    visibility: show;
  }
}
.login_bg_ {
  height: 100%;
  overflow: hidden;
  background: url('../assets/home_bg.jpg') no-repeat;
  background-size: 100% 100%;
  background-position: top left;
  .top_header_ {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    padding: 12px 0 0 43px;
    height: 60px;
    // margin-bottom: 60px;
    .com_ {
      color: #333333;
      font-size: 15px;
      margin-left: 42px;
    }

    .logo_ {
      width: 102px;
      height: auto;
    }
    .lang_ {
      float: right;
      margin-right: 24px;
      cursor: pointer;
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;

  .left_wrap_ {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    width: 373px;
    margin-right: 143px;
    .list_ {
      li {
        &::before {
          content: '';
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin-right: 8px;
          background: #fff;
          vertical-align: middle;
        }
      }
    }
    .line_ {
      width: 36px;
      height: 3px;
      background: rgba(255, 255, 255, 1);
    }
    .title_ {
      font-size: 26px;
      margin: 28px 0;
    }
    .copyright_ {
      margin: 29px 0 19px;
    }
    .fs9_ {
      font-size: 9px;
    }
  }
}
.login_container_ {
  display: inline-block;
  vertical-align: middle;
  width: 343px;
  height: 343px;
  padding: 31px 42px 0;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(121, 118, 118, 0.3);
  * {
    box-sizing: border-box;
  }
  .login_title_ {
    margin-bottom: 29px;
    height: 27px;
    cursor: pointer;

    li {
      display: inline-block;
      color: #999999;
      font-size: 12px;
      transition: all 0.3s ease-in;
      margin-right: 29px;
      &.active_ {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
      }
    }
  }
  .veri_code_ {
    width: 90px;
    height: 32px;
    background: rgba(239, 242, 255, 1);
    position: absolute;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    line-height: 0;
    right: 12px;
  }
  .steps_wrap_ {
    font-size: 9px;
    color: #666666;
    text-align: center;
    margin: 26px 0 42px;

    li {
      display: inline-block;
      vertical-align: middle;
    }
    .desc_01_ {
      width: 96px;
    }
    .desc_02_ {
      width: 66px;
    }
    .step_ {
      display: block;
      width: 16px;
      height: 16px;
      margin: 0 auto;
      border-radius: 50%;
      line-height: 16px;
      background: #23e2b7;
      color: #fff;
      text-align: center;
      margin-bottom: 7px;
    }
    .arrow_ {
      display: inline-block;
      vertical-align: middle;
      width: 40px;
      height: 21px;
      background: url('../assets/arrow.png') no-repeat;
      background-size: 100% auto;
      margin: 0 28px 0 23px;
    }
  }
}
.login_form_ .login-form-button {
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(90deg, rgba(35, 226, 183, 1) 100%);
  border-radius: 3px;
  border: none;
  &:hover {
    background: linear-gradient(90deg, rgba(35, 226, 183, 0.6) 100%);
  }
  &:focus {
    background: linear-gradient(90deg, rgba(35, 226, 183, 1) 100%);
  }
}
</style>
