<template>
  <div>
    <a-modal
      @cancel="handleHideModal"
      :footer="null"
      class="upload_modal_"
      :visible="visible"
      :title="
        `${
          type == 3
            ? $t('btns[10]') || '上传发票'
            : $t('btns[11]') || '上传资料'
        }`
      "
    >
      <a-form class="horizontal_form_ item_line_" layout="horizontal">
        <div class="pay-remarks">{{ uploadDescInfo.payRemarks }}</div>
        <a-form-item
          :key="index"
          v-for="(item, index) in uploadDescInfo.list"
          :label="item.typeName"
        >
          <a-upload
            multiple
            :fileList="fileListInfo[index]"
            :remove="file => handleRemove(file, index)"
            :beforeUpload="file => beforeUpload(file, index)"
          >
            <a-button>{{ $t('labels[41]') || '选择文件' }}</a-button>
          </a-upload>
        </a-form-item>
        <!-- <a-form-item v-if="type != 3" label="费用清单">
          <a-upload
           :fileList="fileList1" :remove="handleRemove1" :beforeUpload="beforeUpload1"
          >
            <a-button>{{$t('labels[41]')||'选择文件'}}</a-button>
          </a-upload>
        </a-form-item> -->
        <p style="color: #020101;">{{ $t('uploadModal.remark') }}</p>
        <a-row>
          <a-form-item>
            <a-textarea :placeholder="$t('uploadModal.input')" v-model="remark">
            </a-textarea>
          </a-form-item>
        </a-row>
        <a-row>
          <a-col :span="10">
            <span style="color: #f00;font-weight: bold;">{{
              $t('uploadModal.needFill')
            }}</span>
          </a-col>
          <a-col :span="2">
            <a-switch :checked="checked" @change="onChange" />
          </a-col>
        </a-row>
        <a-row style="margin-top: 24px;">
          <a-col :offset="15" :span="9">
            <a-button @click="handleHideModal" plain>{{
              $t('Modals[13]') || '取消'
            }}</a-button>
            <a-button
              style="margin-left: 8px;"
              type="primary"
              @click="handleUpload"
              :disabled="ifUpload === false"
              :loading="uploading"
              >{{ $t('Modals[14]') || '确认' }}</a-button
            >
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<style lang="scss" scoped>
.pay-remarks {
  margin-bottom: 10px;
}
</style>
<script>
export default {
  props: {
    uploadDescInfo: {
      type: Object,
      default: () => {}
    },
    visible: {
      type: Boolean,
      default: false
    },
    claimId: {
      type: String,
      default: undefined
    },
    type: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      fileListInfo: {
        0: []
      },
      fileList: [],
      fileList1: [],
      uploading: false,
      remark: '',
      checked: false,
      flag: ''
    }
  },
  computed: {
    ifUpload() {
      let uploadFlag = false
      Object.keys(this.fileListInfo).forEach(key => {
        const list = this.fileListInfo[key]
        if (list.length > 0) {
          uploadFlag = true
        }
      })
      return !!this.remark || uploadFlag
    }
  },
  methods: {
    uploadFile(files) {
      let uploadRequest = null
      if (this.type == 1) {
        // 预授权
        uploadRequest = this.$apis.uploadFile
      } else if (this.type == 2) {
        // 就诊
        uploadRequest = this.$apis.uploadFileOfClaim
      } else if (this.type == 3) {
        // 发票
        uploadRequest = this.$apis.uploadInvoiceFileOfClaim
      } else if (this.type == 4) {
        // 未收件上传资料
        uploadRequest = this.$apis.uploadImageFiles
      }
      uploadRequest(files)
        .then(res => res.data)
        .then(() => {
          this.handleHideModal()
          this.$emit('success')
          this.$message.success(this.$t('tips[1]') || '上传成功')
        })
        .finally(() => {
          this.uploading = false
        })
    },
    handleHideModal() {
      this.fileListInfo = []
      this.remark = ''
      this.$emit('update:visible')
    },
    handleRemove(file, i) {
      const currentFiles = this.fileListInfo[i]
      const index = currentFiles.indexOf(file)
      const newFileList = currentFiles.slice()
      newFileList.splice(index, 1)
      this.fileListInfo[i] = newFileList
      this.checkFlag()
    },
    beforeUpload(file, i) {
      const currentFiles = this.fileListInfo[i] || []
      file.typeCode = this.uploadDescInfo.list[i].typeCode
      this.fileListInfo[i] = [...currentFiles, file]
      this.fileListInfo = { ...this.fileListInfo }
      this.checkFlag()
      return false
    },
    handleUpload() {
      let types = []
      const formData = new FormData()
      Object.keys(this.fileListInfo).forEach(key => {
        const list = this.fileListInfo[key]
        list.forEach(file => {
          if (this.type == 3) {
            formData.append('file', file, file.name)
            formData.append('type', file.typeCode)
          } else {
            formData.append('files', file, file.name)
            types.push(file.typeCode)
          }
        })
      })
      if (this.type == 2) {
        formData.append('remark', this.remark)
        formData.append('flag', this.flag)
      }
      formData.append('claimId', this.claimId)
      formData.append('types', types.join(','))

      this.uploading = true
      // You can use any AJAX library you like
      this.uploadFile(formData, types)
    },
    checkFlag() {
      let flagarr = []
      for (let key in this.fileListInfo) {
        if (this.fileListInfo[key].length > 0) {
          flagarr.push('true')
        } else {
          flagarr.push('false')
        }
      }
    },
    onChange(checked) {
      this.checked = checked
      this.flag = 'N'
      if (checked) {
        this.flag = 'Y'
      }
    }
  }
}
</script>
